import React from 'react';
import {Link} from 'gatsby';

import {withFirebase} from '../Firebase';
import * as ROUTES from '../../constants/routes';

const ForgotPasswordForm = (props) => {
	const inititalState = {
		email: '',
		sendResetLinkSuccessful: false,
		error: null,
	};
	const [passwordResetCredentials, setPasswordResetCredentials] = React.useState({
		...inititalState,
	});
	const {email, sendResetLinkSuccessful, error} = passwordResetCredentials;

	const handleChange = (event) => {
		setPasswordResetCredentials({[event.target.name]: event.target.value});
	};

	const onSubmitForm = (event) => {
		props.firebase
			.doPasswordReset(email)
			.then(() => {
				setPasswordResetCredentials({
					...inititalState,
					sendResetLinkSuccessful: true,
				});
			})
			.catch((Error) => {
				setPasswordResetCredentials({
					...passwordResetCredentials,
					error: Error,
				});
			});
		event.preventDefault();
	};

	return (
		<div className="contentWrap">
			{error && <p className="errorMessage">{error.message}</p>}
			<form onSubmit={onSubmitForm}>
				<input
					className="textBox"
					value={email}
					onChange={handleChange}
					required
					type="email"
					id="email"
					name="email"
					placeholder="Email ID"
					autoComplete="email"
					autoFocus
				/>
				<button className="buttonSignin" type="submit">
					<p className="inButtonText">Proceed</p>
				</button>
				{sendResetLinkSuccessful && <p>Please check your email for the password reset link.</p>}
			</form>
		</div>
	);
};

const ForgotPasswordLink = () => <Link to={ROUTES.FORGOT_PASSWORD}>Forgot Password?</Link>;

export {ForgotPasswordLink};

export default withFirebase(ForgotPasswordForm);
