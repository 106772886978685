import React, {Fragment} from 'react';
import {Box} from '@material-ui/core';

import Layout from '../components/layout';
import ForgotPasswordForm from '../components/ForgotPassword';

const ForgotPasswordPage = () => (
	<Fragment>
		<ForgotPasswordForm />
	</Fragment>
);

export default (props) => (
	<Layout location={props.location}>
		<ForgotPasswordPage />
	</Layout>
);
